<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">YOUR INFORMATION's</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-6">
        <v-layout row wrap class="align-center mx-2 ">
          <v-flex xs12 md12>
            <EmployeeDetails
              v-on:data="on_update_status_employee"
              :position="position"
              :member_information="member_information"
              :compensation_histories="compensation_histories"
              :memo_history="memo_history"
              :change_history="change_history"
              :cropped="cropped"
              :is_personal="true"
            ></EmployeeDetails>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from "vuex";
  import EmployeeDetails
    from "@/views/file_interface/search/components_emloyee/tabs/EmployeeDetails";

  export default {
    components: {
      EmployeeDetails,
    },
    data() {
      return {
        search_items: [],

        cropped: require('@/assets/images/avatars/1.png'),
        branch: '',
        member_information: {category: '', branch: '', employee_position: ''},
        compensation_histories: [],
        memo_history: [],
        change_history: [],
      }
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position', 'employee_id']),
    },
    methods: {
      ...mapActions('employee', ['search_all_by_status_employee',]),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      initialize_data() {
        this.search_all_by_status_employee({
          search_word: this.employee_id,
          status: 'Active',
          search_by: 'Personal',
        })
          .then(response => {
            this.search_items = response.data
            if (this.search_items.length > 0) {
              var value = this.search_items[0]
              this.member_information = value.details
              this.compensation_histories = value.details.compensation_history
              this.memo_history = value.details.memo_history
              this.change_history = value.details.change_history
              this.cropped = value.image != 'http://127.0.0.1:8000/storage' ? value.image : this.avatarImg
            }
          })
      },
      async on_update_status_employee() {
        location.reload()
      },
    }
  }
</script>
